.cardContainer {
    /* background-color: rgba(0, 105, 119, 0.171); */
    width: 100%;
}

.box {
    display: grid;
    grid-template-columns: repeat(auto-fit, 390px);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.card {
    height: 510px;
    width: 390px;
    background-color: #fff;
    transform: scale(0.7);
    border-radius: 8px;
    box-shadow: 0px 0px 30px 1px #9c9c9cf3;
}

.info {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.name {
    font-size: 35px;
    font-weight: 600;
    color: #f79f08;
    position: absolute;
    top: 100px;
    letter-spacing: 2px;
}

.infoName {
    font-size: 25px;
    color: #555;
    position: absolute;
    top: 140px;
}

.divider{
    background-color: #949594;
    height: 1.5px;
    width: 50px;
    position: absolute;
    top: 190px;
}

.bio {
    position: absolute;
    top: 220px;
    color: #949594;
    line-height: 28px;
    font-size: 18px;
    text-align: justify;
    padding: 0 30px;
}

.botaoContato {
    position: absolute;
    bottom: 30px;
    background-color: #f79f08;
    border: none;
    font-size: 20px;
    padding: 13px 25px;
    cursor: pointer;
    color: #fff;
}

.photo {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    background-size: 100% 100%;
    transition: 1s;
}

.photo-1 {
    background-image: url(./img/photoTeste.svg);
}

.photo-2 {
    background-image: url(./img/photoTeste.svg);
}

.photo-3 {
    background-image: url(./img/photoTeste.svg);
}

.photo-4 {
    background-image: url(./img/photoTeste.svg);
}

.photo-5 {
    background-image: url(./img/photoTeste.svg);
}

.photo-6 {
    background-image: url(./img/photoTeste.svg);
}

.photo-7 {
    background-image: url(./img/photoTeste.svg);
}

.photo-8 {
    background-image: url(./img/photoTeste.svg);
}

.photo-9 {
    background-image: url(./img/photoTeste.svg);
}
.card:hover .photo {
    transform: scale(0.50, 0.40) translateY(-550px);
    border-radius: 50%;
    background-size: 100% 140%;
    background-position: 0 -100px;/* Posição da Imagem dentro do circulo*/
    border-bottom: 10px solid #006c7a;
    bottom: 35px;
}

.botaoSaibaMais {
    position: absolute;
    bottom: -70px;
    font-size: 25px;
    color: #ffffff;
    background-color: rgb(247, 52, 52);
    width: 100%;
    padding: 12px;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
}