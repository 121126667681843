body {
    font-family: 'arial';
}

#corpo {
    padding: 0;
}

.secao-principal {
    margin: 0;
}

#corpo>.conteiner {
    margin: 0;
    padding: 0;
    width: 100% !important;
    display: block;
    max-width: unset;
}

h1.titulo {
    display: none !important
}

.breadcrumbs {
    display: none !important
}

.h3MM,
.pMM {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.h3MM {
    font-size: .75rem;
    font-weight: bold;
}

.pMM {
    font-size: 10px;
    max-width: 260px;
    line-height: 17px;
}

.tituloMM {
    padding: 80px 0;
    font-size: 2rem;
    text-align: center;
    border-radius: 10px;
    background-color: #f1f1f6;
}

.containerMM {
    display: grid;
    justify-content: center;
    width: 100%;
    margin: 100px 0;
}

.nossasLojasMM {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 50px;
}

.conteudoMM {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    border-radius: 25px;
    max-width: 800px;
    box-shadow: #00000021 1px 1px 10px 3px;
}

.mapaMM {
    border-radius: 25px;
    z-index: 0;
    width: 100%;
    background-image: url("./loading-mapa.gif");
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: center center;
}

.descricaoMM {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 10px;
}

.descricaoMM p {
    flex-wrap: wrap;
}

@media (max-width: 800px) {
    .nossasLojasMM {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}






.mapaMM {position: relative; overflow: hidden; height: 300px;}

.localImg {position: absolute; animation: animationMap 10s infinite ease-in-out;}

@keyframes animationMap {
      0% {
        left: 100%;
      }
      10% {
        left: 0%;
      }
      60% {
        left: 0%;
      }
      70% {
        left: 100%;
      }
      100% {
        left: 100%;
      }
}