#Historia .historia-container .titulo-historia {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 3rem;
}

#Historia .historia-container .titulo-historia::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 5rem;
    height: 3px;
    background: #5ec79c;
    margin-bottom: 1rem;
}

#Historia .historia-container .historia {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: center;
    padding-bottom: 5rem;
    margin: 5rem;
    gap: 30px;
    box-shadow: 0 0 10px 1px #0000006e;
}

#Historia .historia-container .historia .historia-img {
    margin-top: 3rem;
    margin-left: 3rem;
}

#Historia .historia-container .historia .historia-img img {
    border-radius: 10px;
}

#Historia .historia-container .historia .historia-text {
    font-size: 1rem;
    line-height: 30px;
    margin-top: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

#Historia .historia-container .historia .historia-text p {
    margin-bottom: 20px;
}

#Historia .historia-container .historia .historia-text .author {
    width: 100%;
    text-align: right;
    font-size: 85%;
}

@media ( max-width: 700px) {
    #Historia .historia-container .historia {
        grid-template-columns: 1fr;
        margin: 1rem;
        gap: 0px;
        box-shadow: 0 0 10px 1px #00000024;
    }
    
    #Historia .historia-container .historia .historia-img {
        margin: 1rem;
    }
    
    #Historia .historia-container .historia .historia-text {
        margin: 1rem;
    }
}