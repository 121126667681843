@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins&display=swap');

.section1 {
    background-image: url('./qualidade.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.bg-section1 {
    background-color: rgba(0, 54, 43, 0.726);
}

.section1-titulo {
    color: #fff;
    font-size: 2.5rem;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-weight: bold;
    text-align: center;
    padding-top: 50px;
}

.section1-conteudo {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 50px 100px;
}

.section1-item {
    background-color: #fff;
    max-width: 350px;
    padding: 10px;
}

.section1-item p {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
    color: #033A62;
}

.section1-item img {
    width: 100%;
}

@media (max-width: 600px) {
    .section1-titulo {
        font-size: 1.75rem;
        padding: 15px;
    }
    
    .section1-conteudo {
        flex-direction: column;
        align-items: center;
        padding: 10px 20px 30px 20px;
    }
}