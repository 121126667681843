.produtosss {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50%;
    flex-wrap: wrap;
}

.produtosss h1 {
    font-weight: 900;
    font-size: 3rem;
    text-align: center;
}

/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/


.bg-box {
    background-image: url('./empresa.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #b7dfff9c;
    padding: 30px;
}

.box-grid {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(3, 1fr);
    /* background-color: #3b918d79; */
    gap: 20px;
}

div.box-produto {
    margin: 3px 3px;
    font-family: sans-serif;
    background-color: white;
}

.box-produto {
    /* width: 400px;
    height: 600px; */

    /* width: 300px;
    height: 450px; */
    position: relative;
    perspective: 1000px;
    border-radius: 10px;
}

.box-produto .box-img {
    transform: rotateY(0);
    transition: all 0.5s ease-in-out 0s;
}


/* .box-produto:hover .box-img {
    transform: rotateY(-90deg);
} */

.box-produto .box-img img{
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.box-produto .content {
    color: white;
    background: rgba(28, 43, 49, 0.8);
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 110px 50px;
    box-sizing: border-box;
    transform: rotateY(90deg);
    transition: 1s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.box-produto:hover .content {
    transform: rotateY(0);
}

.box-produto:hover .box-img {
    filter: blur(3px);
}

.box-produto .content p {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 20px 0;
}

.box-produto ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.box-produto ul li {
    list-style: none;
    padding-top: 50px;
}

.box-produto ul li img {
    width: 40%;
    cursor: pointer;
}

.titulo-produto {
    display: flex;
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 80px;
    background-color: #00000060;
    z-index: 1;
    justify-content: center;
    align-items: center;
}

.titulo-produto h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
}



/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/
/*/////////////////////////////////////////////////*/



/* USAR GRID 1FR 1FR 1FR 1FR PARA MAIS PRODUTOS*/

/* @media ( max-width: 1720px) {
    .box-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media ( max-width: 1300px) {
    .box-grid {
        grid-template-columns: repeat(2, 1fr);
    }
} */

@media (max-width: 1024px) {
    .box-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 800px) {
    .box-grid {
        grid-template-columns: 1fr;
    }
}



/* @media (max-width: 400px) {
    .box-produto {
        width: 350px;
        height: 525px;
    }
} */