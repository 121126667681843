* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  min-height: 100vh;
  /* Removido o background-color do body para evitar conflitos */
}

header {
  height: 104px;
  width: 100%;
  min-height: 66px;
  background-color: #509ba5;
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

header .logo {
  color: #fff;
  font-size: 1.75em;
  font-weight: 600;
  text-decoration: none;
}

header ul {
  position: relative;
}

header ul li {
  position: relative;
  list-style: none;
  float: left;
}

header ul li a {
  color: #fff;
  font-size: 1.1em;
  padding: 20px 25px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

header ul li a:hover {
  background-color: #509ba5;
  color: #78e2b6;
}

header ul li ul {
  position: absolute;
  left: 0;
  width: 250px;
  background-color: #fff;
  display: none;
  z-index: 9;
}

header ul li:hover > ul {
  display: block;
}

header ul li ul li {
  position: relative;
  width: 100%;
}

header ul li ul li a {
  color: #000;
  border-left: 5px solid transparent;
  padding: 12px 25px;
  transition: all 0.3s ease;
}

header ul li ul li a:hover {
  background-color: rgba(20, 148, 199, 0.158);
  color: #000;
  border-left: 5px solid #3b918c;
  padding-left: 50px;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

header ul li ul li ul {
  top: 0;
  left: 200px;
}

/* Responsividade */

@media (max-width: 900px) {
  header {
    padding-left: 15px;
    padding-right: 35px;
    position: relative;
    top: 0;
  }
  header.active {
    position: fixed;
  }
  header nav {
    position: absolute;
    width: 100%;
    top: 104px;
    left: 0;
    background-color: #445964;
    display: none;
    z-index: 1;
    overflow: auto;
  }
  header.active nav {
    display: initial;
    position: fixed;
    height: calc(100vh - 104px);
  }
  header nav ul li {
    width: 100%;
  }
  header nav ul li ul {
    position: relative;
    width: 100%;
    left: 0;
  }
  header ul li ul li ul {
    top: 0;
    left: 0;
  }
  header ul li ul li a {
    color: #fff;
    padding-left: 50px;
  }
  header nav ul li:hover ul li {
    background-color: #546e7b;
  }
  .menuToggle {
    position: relative;
    width: 40px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menuToggle::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: translateY(-12px);
    box-shadow: 0 12px #fff;
    transition: 0.5s;
  }
  .menuToggle::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: translateY(12px);
    transition: 0.5s;
  }
  header.active .menuToggle::before {
    transform: rotate(45deg);
    box-shadow: 0 0 #fff;
  }
  header.active .menuToggle::after {
    transform: rotate(315deg);
    box-shadow: 0 0 #fff;
  }
}
