.btn {
    position: relative;
    bottom: 100px;
    top: 50%;
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
}

.btn.btn-primary {
    color: #fff;
    background-color: #03c2a8;
    transition: all .2s ease-in-out 0s;
}

.btn.btn-primary:hover {
    transform: scale(.95);
}
