.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: auto;
  max-height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

/*.slide {
  opacity: 0;
  transition-duration: 1s ease;
}*/

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media (max-width: 390px) {
  .slider {
    height: 50vh;
    align-items: start;
    padding-top: 30px;
  }
  .left-arrow {
    top: 315px;
    left: 20%;
  }

  .right-arrow {
    top: 315px;
    right: 20%;
  }
}
