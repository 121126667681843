.error-404 {
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e600, #69a6ceb7, #69c0d179, #4789bc00, #69c0d100, #59c6cc00, #3b6fa300);
    height: 100vh;
    background-size: 400%;
}

.error-page {
    position: absolute;
    top: 20%;
    left: 17%;
    right: 17%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.error-page .error-container {
    max-width: 700px;
    text-align: center;
}

.error-container h1 {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 18vw;
    line-height: 1em;
    position: relative;
}

.error-container h1::after {
    position: absolute;
    content: attr(data-text);
    top: 0;
    left: 0;
    right: 0;
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #69bfd1, #478abc, #69bfd1, #59c6cc, #3b6fa3);
    background-size: 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
    animation: animate-404 10s ease-in-out infinite;
}

@keyframes animate-404 {
    0% { background-position: 0 0;}
    25% { background-position: 100% 0;}
    50% { background-position: 100% 100%;}
    75% { background-position: 0 100%;}
    100% { background-position: 0 0;}
}

.error-container h4 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #000;
    max-width: 700px;
    font-size: 2em;
    position: relative;
    font-family: 'Poppins';
    font-weight: bold;
}

.error-container h4::after {
    position: absolute;
    content: attr(data-text);
    top: 0;
    left: 0;
    right: 0;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.error-container p {
    font-size: 1.2em;
    color: #0d0d0d;
}

.error-container .error-btns {
    margin: 25px 0;
    display: inline-flex;
}

.error-container .error-btns a {
    display: inline-block;
    margin: 0 10px;
    text-decoration: none;
    border: 2px solid #69a6ce;
    color:  #69a6ce;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 25px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    cursor: pointer;
}

.error-container .error-btns a:hover {
    color: #fff;
    background-color: #69a6ce;
}

@media (max-width: 700px) {
    .error-404 {
        display: flex;
        align-items: center;
        height: 75vh;
        padding: 50px 20px;
    }
    .error-page {
        position: initial;
        padding: 50px 0;
    }
    .error-container .error-btns {
        margin: 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 20px;
    }
    .error-container .error-btns a {
        width: 100%;
        max-width: 270px;
    }

    .error-container p {
        font-size: 1em;
        padding: 0 10px;
    }
}