.section2 {
    background-color: #007d81;
}

.section2-conteudo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px;
}

.section2-conteudo svg {
    max-width: 60px;
}

.section2-conteudo p {
    font-size: 2rem;
    color: #fff;
    font-family: 'Open Sans';
    font-weight: bold;
}

@media (max-width: 600px) {
    .section2-conteudo {
        flex-direction: column;
        gap: 0;
        padding: 20px;
    }
    
    .section2-conteudo p {
        font-size: 1.5rem;
        text-align: center;
    }
}