.titulo-banner {
    background-image: url("./bg-page-title-2.jpg");
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
}

.titulo-banner .bg {
    background-color: #00000080;
    padding: 70px 0px;
}

.titulo-banner h1 {
    font-size: 3rem;
    text-transform: uppercase;
    color: #fff;
}

.valores-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
}

.valores-container h1 {
    font-size: 2rem;
    padding: 20px 0;
}

.valores-container .titulo-valores {
    position: relative;
}

.valores-container .titulo-valores::after {
    content: '';
    position: absolute;
    display: flex;
    top: 60px;
    width: 100%;
    height: 2px;
    background: #5ec79c;
}

.valores-container p {
    text-align: justify;
    max-width: 1000px;
}

.valores-conteudo {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.valores-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.valores-item-h {
    display: flex;
    border: 1px solid rgb(212, 212, 212);
    padding: 20px;
    gap: 20px;
    transition: .5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

}

.valores-item-v {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    border: 1px solid rgb(212, 212, 212);
    justify-content: space-evenly;
    transition: .5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

}
.valores-item-h h1, .valores-item-v h1 {
    transition: .5s;
}

.valores-descricao {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 15px;
}

.valores-descricao h1 {
    font-size: 1.25rem;
}

.valores-descricao p {
    text-align: justify;
}

.valores-item-h:hover, .valores-item-v:hover {
    box-shadow: 0px 0px 5px 0px #c0c0c0;
    transition: .5s;
}

.valores-item-h:hover h1, .valores-item-v:hover h1 {
    color: rgb(255, 105, 50);
    transition: .5s;
}