#Receita .receita-container{
    display: grid;
    justify-items: center;
}

#Receita .receita-container .item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    padding-bottom: 5rem;
    margin: 5rem;
    gap: 30px;
    box-shadow: 0 0 10px 1px #0000006e;
}

#Receita .receita-container .item .item-img {
    margin: 3rem;
}

#Receita .receita-container .item .item-img .titulo-receita {
    text-align: center;
}

#Receita .receita-container .item .item-img .titulo-receita h1 {
    font-size: 2rem;
    font-weight: 600;
}

#Receita .receita-container .item .item-img .titulo-receita::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 5rem;
    height: 3px;
    background: #5ec79c;
    margin-bottom: 1rem;
}

#Receita .receita-container .item .item-img img {
    border-radius: 20px;
    width: 500px;
}

#Receita .receita-container .item .ingredientes li {
    font-size: 1.25rem;
    line-height: 40px;
}

#Receita .receita-container .item .ingredientes h1 {
    font-size: 1.75rem;
    font-weight: 600;
}

#Receita .receita-container .item .modo-preparo {
    margin: 0 3rem;
}

#Receita .receita-container .item .modo-preparo li {
    font-size: 1rem;
    line-height: 25px;
    margin-top: 15px;
}

#Receita .receita-container .item .modo-preparo li::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 15px;
    background-color: #000;
}

#Receita .receita-container .item .modo-preparo h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

@media (max-width: 700px) {
    #Receita .receita-container .item {
        grid-template-columns: 1fr;
        margin: 1rem;
        box-shadow: 0 0 10px 1px #00000024;
    }
    
    #Receita .receita-container .item img {
        border-radius: 10px;
    }
    
    #Receita .receita-container .item .item-img {
            margin: 1rem;

            display: flex;
            flex-direction: column;
            align-items: center;
    }
    
    #Receita .receita-container .item .modo-preparo {
            margin: 0 1rem;
    }
    
    #Receita .receita-container .item .ingredientes li,
    #Receita .receita-container .item .ingredientes h1 {
        padding-left: 15px;
    }
}