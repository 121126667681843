@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Poppins', sans-serif; */
}

.copyright {
    width: 100%;
    color: #fff;
    text-align: center;
    padding-top: 40px;
}

.copyright p {
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
}

.copyright span {
    text-transform: uppercase;
    opacity: .6;
    font-weight: 200;
}

footer{
    background-color: #509ba5;
    padding: 50px 0 0 0;

}

.container-footer{
    max-width: 1400px;
    padding: 0 4%;
    margin: auto;
}
.row-footer{
    display: flex;
    flex-wrap: wrap;
}

.footer-col{
    width: 25%;
    padding: 0 15px;
}
.footer-col h4{
    font-size: 22px;
    color: white;
    margin-bottom: 20px;
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
}
.footer-col ul{
    list-style: none;
}
.footer-col ul li{
    margin: 10px 0;
}
.footer-col ul li a{
    font-size: 16px;
    text-transform: capitalize;
    color: white;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all 0.3s ease;
}
.footer-col ul li a:hover{
    color: #cecdcd;
    padding-left: 10px;
}
.footer-col .medias-socias{
    margin-top: 30px;
}
.footer-col .medias-socias a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    border-radius: 50%;
    color: white;
    border: 1px solid white;
    transition: all 0.5s ease;
}
.footer-col .medias-socias a i{
    font-size: 20px;
}

.footer-col .medias-socias a:hover{
    color: #509ba5;
    background-color: white;
}
.footer-col .form-sub input{
    width: 100%;
    padding: 10px;
    font-size: 15px;
    outline: none;
    border: 1px solid white;
    color: white;
    background-color: #509ba5;
}
.footer-col .form-sub input::placeholder{
    color: white;
}
.footer-col .form-sub button{
    width: 100%;
    margin-top: 10px;
    padding: 10px; 
    font-size: 17px;
    outline: none;
    border: none;
    cursor: pointer;
    color: #3b918c;
    border-radius: 3px;
    font-weight: bold;
    background-color: white;        
}

.logo-WID {
    display: flex;
    justify-content: center;
    filter: invert(0);
}

footer .desenvolvimento {
    position: relative;
    background-color: #1a1a1a;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 20px;
}

footer .desenvolvimento:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #9C27B0, #8844ee, #673AB7, #7f3f99, #442277);
}

footer .desenvolvimento .world-in-designer img {
    display: block;
}

/* Responsivo */
@media (max-width: 800px) {
    .footer-col{
        width: 50%;
        margin-bottom: 30px;
    }
}
@media (max-width:600px) {
    .footer-col{
        width: 100%;
    }
}