.EnvioEmail {
    display: flex;
    justify-content: center;
    padding: 50px 25px;
}

.ContainerEnvio {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ContainerEnvio h1,
.ContainerEnvio h3,
.ContainerEnvio h5,
.ContainerEnvio button {
    font-family: 'Poppins';

}

.ContainerEnvio img {
    width: 100%;
    max-width: 150px;
    padding: 20px 0;
}

.ContainerEnvio h1 {
    font-size: 2rem;
    font-weight: 500;
}

.ContainerEnvio h3 {
    font-size: 1.25rem;
    font-weight: 400;
}

.ContainerEnvio h5 {
    font-size: .9rem;
    color: #000000af;
}

.ContainerEnvio button {
    font-size: 1.2rem;
    padding: 10px 50px;
    color: #fff;
    background-color: #00BA00;
    margin-top: 50px;
    transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.ContainerEnvio button:hover {
    scale: 1.1;
    transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.iconCheck {
    font-size: 6rem;
    color: #00BA00;
}

@media (max-width: 700px) {
    .ContainerEnvio h1 {
        font-size: 1.5rem;
    }

    .ContainerEnvio h3 {
        font-size: 1rem;
    }
    
    .ContainerEnvio h5 {
        font-size: .8rem;
    }
}