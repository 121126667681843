.section4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 70px 0;
}

.section4 h1 {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    padding-bottom: 40px;
    color: #3b918c;
}

.section4-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    justify-content: center;
}

.section4-item {
    position: relative;
    width: 400px;
    height: 420px;
    border-radius: 10px;
    border: 1px solid #d5d5d56b;
    box-shadow: 0 0 10px 3px #ececec;
    transition: .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    filter: grayscale(.4);
}


.section4-item:hover a {
    position: absolute;
    border-radius: 10px;
    top: 0;
    width: 100%;
    height: 100%;
}

.section4-item:hover {
    scale: 1.05;
    transition: .3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    filter: grayscale(0);
}

.section4-img {
    width: 398px;
    height: 265px;
}

.section4-img img {
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    width: 398px;
    height: 265px;
}

.section4-descricao {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.section4-descricao span:nth-child(1) {
    font-size: 1.1rem;
    font-weight: bold;
    color: #3b918c;
    padding-top: 10px;
}


.section4-descricao span:nth-child(2) {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
}

@media (max-width: 600px) {
    .section4-container {
        flex-direction: column;
        align-items: center;
    }

    .section4-item {
        width: 400px;
        height: 420px;
    }
}

@media (max-width: 400px) {
    .section4 {
        padding: 50px 0;
    }
    
    .section4-item {
        width: 350px;
        height: 378px;
    }

    .section4-descricao span:nth-child(2) {
        padding: 0 10px;
    }

    .section4-img {
        width: 100%;
    }

    .section4-img img {
        width: 100%;
    }

    .section4-item {
        height: auto;
        padding: 20px;
    }
}

