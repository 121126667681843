.section3 {
    background-image: url('./mesa-milk-cafe-leite2.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.bg-section3 {
    background-color: rgba(0, 0, 0, 0.726);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.section3-titulo h1 {
    color: #fff;
    font-size: 2rem;
    font-family: 'Open Sans';
    font-weight: bold;
    text-align: center;
    padding-bottom: 50px;
}

.section3-video {
    display: flex;
    justify-content: center;
    width: 70%;
    padding-bottom: 30px;
}

.section3-video video {
    border-radius: 10px;
}

.section3-botao {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding-top: 30px;
}

.section3-botao a {
    color: #f3f3f3;
    padding: 10px;
    border-radius: 5px;
    background-color: #c53836;
    border-color: #a52f2d;
    width: 100%;
    max-width: 300px;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 600px) {
    .bg-section3 {
        height: auto;
        padding: 50px 0;
    }
    
    .section3-titulo h1 {
        font-size: 1.5rem;
        padding-bottom: 20px;
    }
    
    .section3-video {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 400px) {
    .bg-section3 {
        /* height: auto; */
        padding-top: 20px;
    }
}