/*Coalhada*/

.CoalhadaSeca {
    background-image: url(./img/CoalhadaSeca.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaFresca {
    background-image: url(./img/CoalhadaFresca.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaMorango {
    background-image: url(./img/CoalhadaMorango.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaAmeixa {
    background-image: url(./img/CoalhadaAmeixa.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaAbacaxi {
    background-image: url(./img/CoalhadaAbacaxi.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaMaracuja {
    background-image: url(./img/CoalhadaMaracuja.png);
    background-size: contain;
    background-color: #fff;
}

.CoalhadaPessego {
    background-image: url(./img/CoalhadaPessego.png);
    background-size: contain;
    background-color: #fff;
}

/*Creme de Leite*/

.CremeDeLeite {
    background-image: url(./img/CremeDeLeite.png);
    background-size: contain;
    background-color: #fff;
}

/*Doce de Leite*/

.DoceDeLeite {
    background-image: url(./img/DoceDeLeite.png);
    background-size: contain;
    background-color: #fff;
}

/*Iogurte*/
.IogurteNatural {
    background-image: url(./img/IogurteNatural.png);
    background-size: contain;
    background-color: #fff;
}

.IogurteMorango {
    background-image: url(./img/IogurteMorango.png);
    background-size: contain;
    background-color: #fff;
}

.IogurteAbacaxi {
    background-image: url(./img/IogurteAbacaxi.png);
    background-size: contain;
    background-color: #fff;
}

.IogurteAmeixa {
    background-image: url(./img/IogurteAmeixa.png);
    background-size: contain;
    background-color: #fff;
}

.IogurteMaracuja {
    background-image: url(./img/IogurteMaracuja.png);
    background-size: contain;
    background-color: #fff;
}

.IogurtePessego {
    background-image: url(./img/IogurtePessego.png);
    background-size: contain;
    background-color: #fff;
}

.IogurteCoco {
    background-image: url(./img/IogurteCoco.png);
    background-size: contain;
    background-color: #fff;
}

/*Leite*/

.LeiteIntegral {
    background-image: url(./img/LeiteIntegral.png);
    background-size: contain;
    background-color: #fff;
}

.LeiteA2 {
    background-image: url(./img/LeiteA2.png);
    background-size: contain;
    background-color: #fff;
}
/*Manteiga*/

.ManteigaSemSal {
    background-image: url(./img/ManteigaSemSal.png);
    background-size: contain;
    background-color: #fff;
}

.ManteigaComSal {
    background-image: url(./img/ManteigaComSal.png);
    background-size: contain;
    background-color: #fff;
}
/*Manteiga Ghee*/

.ManteigaGhee {
    background-image: url(./img/ManteigaGhee.png);
    background-size: contain;
    background-color: #fff;
}

/*Manteiga Ghee*/

.QueijosSantoAntonio {
    background-color: aliceblue;
    background-image: url(./img/QueijosSantoAntonio.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosMussarelaBolinha {
    background-image: url(./img/QueijosMussarelaBolinha.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosFrescal {
    background-image: url(./img/QueijosFrescal.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosRicota {
    background-image: url(./img/QueijosRicota.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosMussarelaBolinhaTemperado {
    background-image: url(./img/QueijosMussarelaBolinhaTemperado.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosMinasPadrao {
    background-image: url(./img/QueijosMinasPadrao.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosMussarelaPalito {
    background-image: url(./img/QueijosMussarelaPalito.png);
    background-size: contain;
    background-color: #fff;
}

.QueijosCoalho {
    background-image: url(./img/QueijosCoalho.png);
    background-size: contain;
    background-color: #fff;
}

/*Manteiga Ghee*/

.Requeijao {
    background-image: url(./img/Requeijao.png);
    background-size: contain;
    background-color: #fff;
}
