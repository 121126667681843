.FAQ {
    display: flex;
    justify-content: center;
    padding: 40px;
}
.container-faq{
    margin: 0 40px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.container-faq h1 {
    color: blueviolet;
}
.container-faq .tab {
    position: relative;
    background: #fff;
    padding: 0 20px 20px;
    box-shadow: 0 5px 15px 2px rgb(0, 0, 0, .5);
    border-radius: 5px;
    overflow: hidden;
}

.container-faq input {
    appearance: none;
}

.container-faq label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.container-faq label::after {
    content: '+';
    position: absolute;
    right: 20px;
    font-size: 2em;
    color: rgb(0, 0, 0, .1);
    transition: transform 1s;
}

.container-faq .tab:hover label::after {
    color: #333;
}

.container-faq .tab input:checked ~ label::after {
    transform: rotate(135deg);
    color: #fff;
}

.container-faq .tab label h2 {
    width: 40px;
    height: 40px;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.25em;
    border-radius: 5px;
    margin-right: 10px;
}

.container-faq .tab input:checked ~ label h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255, .2);
    font-size: 8em;
    justify-content: flex-end;
}

.container-faq .tab:nth-child(1) label h2{
    /*background: linear-gradient(135deg, #70f570, #49c628);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(2) label h2{
    /*background: linear-gradient(135deg, #27f1b5, #05f1e6);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(3) label h2{
    /*background: linear-gradient(135deg, #2c64cc, #25b9d3);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(4) label h2{
    /*background: linear-gradient(135deg, #7525d1, #6848f7);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(5) label h2{
    /*background: linear-gradient(135deg, #f11c4a, #ff79c3);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(6) label h2{
    /*background: linear-gradient(135deg, #bbd61f, #ffe600);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(7) label h2{
    /*background: linear-gradient(135deg, #dd902b, #ff8614);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(8) label h2{
    /*background: linear-gradient(135deg, #1026ec, #6f94e4);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(9) label h2{
    /*background: linear-gradient(135deg, #8d5299, #e9bff3);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab:nth-child(10) label h2{
    /*background: linear-gradient(135deg, #868686, #ebaf0b);*/
    background: linear-gradient(135deg, #27f1b5, #05f1e6);
}

.container-faq .tab label h3 {
    position: relative;
    font-size: 1.25em;
    font-weight: 600;
    color: #333;
    z-index: 10;
}

.container-faq .tab .content-faq {
    max-height: 0;
    transition: 1s;
    overflow: hidden;
}

.container-faq .tab input:checked ~ .content-faq {
    max-height: 100vh;
}

.container-faq .tab .content-faq p {
    position: relative;
    padding: 10px 0;
    color: #333;
    z-index: 10;
}

@media (max-width: 550px) {
    .FAQ {
        padding: 40px 0;
    }

    .container-faq{
        margin: 0 20px;
        gap: 20px;
    }

    .container-faq .tab label h3 {
        width: 250px;
    }
}