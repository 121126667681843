body {
  font-family: Arial, sans-serif;
}

.section-form {
    background-image: url(./leite.png);
    background-position: bottom 0px right 0px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.banner-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(./Background-faixa.png);
    background-position: center;
    background-size: cover;
    height: 250px;
}

.titulo-faixa {
    background-image: url(./TítuloDesktop.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.div-form {
    padding: 100px;
}

.div-form form {
  max-width: 600px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

.div-form input,
.div-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: .5s;
}

.div-form textarea {
    resize: none;
}

.div-form input:focus, .div-form textarea:focus {
    outline-color: #3b918c;
    outline-style: double;
}

.div-form input::placeholder, .div-form textarea::placeholder {
    color: #00000050;
}
.div-button {
    display: flex;
    justify-content: center;
}

.div-button button {
  background-color: #3b918c !important;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.div-button button:hover {
  background-color: #217e79 !important;
}

@media (max-width: 820px){
    .div-form {
        padding: 50px;
    }
}

@media (max-width: 600px){
    .div-form {
        padding: 50px 20px;
    }
    .titulo-faixa {
        font-size: 3rem;
        padding-bottom: 0;
    }
}