.sectionBanner {
    padding: 50px;
    background-color: #d8d8d85d;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
}

.sectionParceiros {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 50px;
    align-items: center;
}

.divParceiros {
    background-color: #d8d8d857;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 50%;
    border-left: 4px solid rgba(105, 199, 199);
    border-right: 4px solid rgba(105, 199, 199);
}

.parceiroTitulo {
    font-size: 1.25rem;
    font-weight: 600;
}

.parceiroInfo.tel{
    font-weight: 500;
}



.Parceiros {
    display: flex    ;
    justify-content: center;
    margin: 50px 20px;
}

.Parceiros h1 {
    font-family: 'Poppins', sans-serif;
    color: #3b918c;
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
}

.Parceiros h1::after {
    content: "";
    width: 120px;
    height: 10px;
    background: #3b918c;
    display: block;
    margin: 0 auto 50px auto;
}

.Parceiros h4 {
    font-family: 'Open Sans';
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 0;
}

.Parceiros .loja {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    width: 700px;
    display: flex    ;
    align-items: center;
}

.Parceiros .select {
    /* width: calc(50% - 5px); */
    width: 500px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid #3b918c;
    color: #3b918c;
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: 3px;
}

.Parceiros .form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 500px;
}

.Parceiros #lojas {
    margin-bottom: 50px;
}

.Parceiros .icon {
    display: flex;
    justify-content: center;
    width: 10%;
}

.Parceiros .conteudo {
    width: 80%;
    padding-left: 10px;
}

.Parceiros .conteudo h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
}

.Parceiros .conteudo p {
    padding-top: 10px;
    font-style: italic;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.Parceiros .conteudo span {
    padding-left: 20px;
}

@media (max-width: 700px) {
    .Parceiros .select,
    .Parceiros .loja  {
        width: calc(100vw - 40px);
    }

    .Parceiros .form {
        max-width: 100%;
    }

    .Parceiros h1 {
        font-size: 2.5rem;
    }
}