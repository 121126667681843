/*
*
*
*   DEPOIMENTOS
*
*
*/

.depoimentos {
    padding: 50px 10px;
}

/* .depoimentos .titulo {
    margin-bottom: 50px;
} */

.depoimentos .titulo h3 {
    font-family: 'Open Sans';
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.depoimentos .titulo h1 {
    font-family: 'Poppins', sans-serif;
    color: #3b918c;
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    margin-top: 20px;
}

.depoimentos .container {
    max-width: 1570px;
    margin: 0 auto;
}



.depoimentos .foto-perfil {
    display: flex;
    justify-content: center;
    padding: 30px;
}
.depoimentos {
    padding: 50px 0;
}

.titulo {
    text-align: center;
    margin-bottom: 30px;
}

.slider {
    position: relative;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.item {
    background: #fff;
    /* border: 1px solid #ddd; */ /*Desativado a borda por enquanto, não tem imagem do perfil*/
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.depoimentos .info {
    padding: 50px;
    display: grid;
    justify-items: center;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 2px #00000020;
}

.depoimentos .depoimento-cliente {
    padding-top: 20px;
    padding-bottom: 20px;
}

.depoimentos .depoimento-cliente p {
    font-size: 18px;
    line-height: 40px;
    color: #787780;
}

.depoimentos .nome h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.depoimentos .cliente span {
    font-size: 14px;
    font-weight: 500;
    color: #509ba5;
    letter-spacing: .1em;
    margin: 0;
    line-height: 25px;
}

.avaliacao span {
    display: flex;
}

@media (max-width: 700px) {
    .depoimentos .info {
        padding: 20px 30px;
    }

    .depoimentos .depoimento-cliente {
        padding-top: 0;
        padding-bottom: 10px;
    }
}